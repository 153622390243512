body {
  text-align: center;
  color: white;
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
}

a, button {
  color: #61dafb;
  background: transparent;
  border: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1 {
  border-bottom: 1px solid #ccc;
  padding: 1em;
  margin-bottom: 1em;
  font-size: 1.5em;
  margin: 0 0 1em;
}

.nav {
  position: absolute;
  top: 0;
  right: 0;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prev, .next {
  color: #61dafb;
  font-size: 3em;
  padding: 10px 20px 16px 20px;
}

.next {
  order: 3;
}

.items li {
  display: flex;
  justify-content: center;
  align-items: center;
}
.items li input {
  margin-left: 20px;
}
.items li label {
  text-align: left;
  padding-left: 5px;
  padding-right: 20px;
  flex: 1;
}