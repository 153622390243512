.settings ul {
  display: inline-block;
}
.settings form {
  margin-bottom: 2em;
}
.settings form label {
  display: block;
}
.settings h2 {
  font-size: 1em;
}
body {
  text-align: center;
  color: white;
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
}

a, button {
  color: #61dafb;
  background: transparent;
  border: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1 {
  border-bottom: 1px solid #ccc;
  padding: 1em;
  margin-bottom: 1em;
  font-size: 1.5em;
  margin: 0 0 1em;
}

.nav {
  position: absolute;
  top: 0;
  right: 0;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prev, .next {
  color: #61dafb;
  font-size: 3em;
  padding: 10px 20px 16px 20px;
}

.next {
  order: 3;
}

.items li {
  display: flex;
  justify-content: center;
  align-items: center;
}
.items li input {
  margin-left: 20px;
}
.items li label {
  text-align: left;
  padding-left: 5px;
  padding-right: 20px;
  flex: 1 1;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

